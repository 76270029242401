import React, { Component, createRef } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { BiCheck } from 'react-icons/bi';
import { ReactComponent as Cut } from '../../assets/cut.svg';

import config from '../../config';
import routeConfiguration from '../../routing/routeConfiguration';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney, convertMoneyToNumber } from '../../util/currency';
import {
  createResourceLocatorString,
  findRouteByRouteName,
  pathByRouteName,
} from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';

import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  OrderPanel,
  AvatarMedium,
  Button,
  Map,
  IconSpinner,
  IconReviewStar,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';
import { sendEnquiry, fetchTransactionLineItems, setInitialValues } from './ListingPage.duck';
import SectionGallery from './SectionGallery';
import { ReactComponent as PDFimg } from '../../assets/Icon awesome-file-pdf.svg';
import css from './ListingPage.module.css';
import { AiOutlineEye } from 'react-icons/ai';
import { BsCheckCircleFill, BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs';
import { BiArrowBack } from 'react-icons/bi';
import classNames from 'classnames';
import { MdLocationOn } from 'react-icons/md';
import { withViewport } from '../../util/contextHelpers';
import moment from 'moment';
import { initiateZeroOrder } from '../CheckoutPage/CheckoutPage.duck';
import { closeListing } from '../ManageListingsPage/ManageListingsPage.duck';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const muttrahCords = { lat: 58.5643, lng: 23.6204 };
const { UUID, Money, LatLng } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find((c) => c.key === key);
  return cat ? cat.label : key;
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      currentTab: 'propertyInfo',
      origin: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      onInitiateZeroOrder,
      currentUser,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const {
      bookingDates,
      quantity: quantityRaw,
      deliveryMethod,
      isQuoteType,
      ...otherOrderData
    } = values;
    const bookingDatesMaybe = bookingDates
      ? {
          bookingDates: {
            bookingStart: bookingDates.startDate,
            bookingEnd: bookingDates.endDate,
          },
        }
      : {};

    const initialValues = {
      listing,
      listingId: listing.id.uuid,
      stockReservationQuantity: 1,
      orderData: {
        quantity: 1,
        // ...bookingDatesMaybe,
        // deliveryMethod,
        // ...otherOrderData,
      },
      confirmPaymentError: null,
    };
    const packageName = listing?.attributes?.publicData?.packages?.name;
    // console.log(147, packageName);
    if (!isQuoteType && packageName !== 'golden') {
      // to be added when next transaction process is ready
      const orderParams = {
        ...initialValues,
        protectedData: { customerEmail: currentUser?.attributes?.email },
      };
      onInitiateZeroOrder(orderParams)
        .then(
          (res) => {
            if (packageName !== 'golden') {
              axios.post(`${apiBaseUrl()}/api/listingBookedMail`, { currentUser, listing });
            }
            return res;
          }
          // packages?.name === 'basic', 'mail two parties'
        )
        .then((res) => {
          const routes = routeConfiguration();
          history.push(createResourceLocatorString('OrderPage', routes, { id: res.id.uuid }, {}));
        })
        .catch((err) => console.log(err));
    } else if (!isQuoteType && packageName === 'golden') {
      // console.log('not basic');
      // const { firstName, lastName } = staffData?.attributes?.profile;
      const data = { listing, currentUser };

      const url = `${apiBaseUrl()}/api/user/createAdminInvestorTx`;

      axios.post(url, data, { withCredentials: true }).then((res) => {
        // console.log(357, res);
        const initialValues = {
          listing: res?.data,
          listingId: res?.data?.id?.uuid,
          stockReservationQuantity: 1,
          orderData: {
            quantity: 1,
          },
          confirmPaymentError: null,
        };
        const orderParams = {
          ...initialValues,
          protectedData: { customerEmail: currentUser?.attributes?.email },
        };
        onInitiateZeroOrder(orderParams)
          .then(async (txResp) => {
            const updatedValues = {
              customerId: currentUser?.id?.uuid,
              transactionId: txResp?.id?.uuid,
              childListingId: res?.data?.id?.uuid,
            };

            await axios.post(`${apiBaseUrl()}/api/user/updateParentListing`, {
              listingId,
              updatedValues,
            });
            // console.log(858, listing);

            axios.post(`${apiBaseUrl()}/api/listingBookedMail`, { currentUser, listing });
            const routes = routeConfiguration();

            history.push(
              createResourceLocatorString('OrderPage', routes, { id: txResp.id.uuid }, {})
            );
            // console.log(111);
          })
          .catch((err) => console.log(err));
      });
    } else {
      const saveToSessionStorage = !this.props.currentUser;

      const routes = routeConfiguration();
      // Customize checkout page state with current listing and selected orderData
      const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

      callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

      // Clear previous Stripe errors from store if there is any
      onInitializeCardPaymentData();

      // Redirect to CheckoutPage
      history.push(
        createResourceLocatorString(
          'CheckoutPage',
          routes,
          { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
          {}
        )
      );
    }
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then((txId) => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }
  componentDidMount() {
    this.setState({ origin: new LatLng(muttrahCords.lat, muttrahCords.lng) });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      customConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      viewport,
      history,
      zeroOrderRequest,
      zeroOrderSuccess,
      zeroOrderError,
      hasBookedListing,
      bookedTx,
      onCloseListing,
    } = this.props;
    const { width } = viewport;
    const setCurrentTab = (tab) => {
      this.setState({ currentTab: tab });
    };
    // console.log(784, currentUser, hasBookedListing);
    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    // console.log(147, currentListing);
    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'details';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
      metadata,
    } = currentListing.attributes || {};

    const isVerified = currentListing?.attributes?.metadata?.isVerified;
    const isSuperAdmin = currentUser?.attributes?.profile?.privateData?.isSuperAdmin;
    const authorName = currentListing?.author?.attributes?.profile?.displayName;
    const downPrice = currentListing?.attributes?.publicData?.downPrice;
    const {
      lookingFor,
      investmentSize,
      investmentOffer,
      businessSector,
      businessType,
      businessDesc,
      lat,
      lng,
      address,
      businessPitch,
      businessEstDate,
      businessEmpCount,
      businessProgress,
      businessMarket,
      businessObjective,
      investmentSizePerDeal,
      packages,
    } = publicData || {};
    const packageName = packages?.name;
    // console.log(123, currentListing, currentListing?.attributes?.privateData);
    const { urlValues = [] } = currentListing?.attributes?.privateData || {};

    const isQuoteType = !!publicData?.isQuoteType;
    const verifiedDatas = currentListing?.attributes?.metadata?.verifiedDatas || [];
    const dateOfEstablishment = moment(businessEstDate).format('MMMM Do YYYY');
    const employeeCount = businessEmpCount?.label;

    // checking for non-basic listing booked or not not

    const alreadyBooked =
      metadata?.appliedusers !== undefined &&
      metadata?.appliedusers?.some((obj) => obj.customerId === currentUser?.id?.uuid);
    let bookedTxId = null;

    if (metadata?.appliedusers !== undefined) {
      for (var i = 0; i < metadata?.appliedusers?.length; i++) {
        if (metadata?.appliedusers[i].customerId === currentUser?.id?.uuid) {
          bookedTxId = metadata?.appliedusers[i].transactionId;
          break; // Exit the loop once a match is found
        }
      }
    }

    // console.log(285, hasBookedListing, alreadyBooked, bookedTxId);

    const origin =
      lat && lng ? new LatLng(lat, lng) : new LatLng(muttrahCords.lat, muttrahCords.lng);
    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = (e) => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleOrderSubmit = (values) => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else if (isAuthenticated) {
        this.handleSubmit(values);
      } else if (!isAuthenticated) {
        history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}));
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map((image) => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find((i) => i.name === variantName) : null;

          return variant || size;
        })
        .filter((variant) => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = listingImages(currentListing, `${config.listing.variantPrefix}-2x`).map(
      (img) => img.url
    );
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );
    // You could add reviews, sku, etc. into page schema
    // Read more about product schema
    // https://developers.google.com/search/docs/advanced/structured-data/product
    const productURL = `${config.canonicalRootURL}${location.pathname}${location.search}${location.hash}`;
    const brand = currentListing?.attributes?.publicData?.brand;
    const brandMaybe = brand ? { brand: { '@type': 'Brand', name: brand } } : {};
    const schemaPriceNumber =
      price &&
      intl.formatNumber(convertMoneyToNumber(price), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
    // console.log(858, currentStock);
    const schemaAvailability =
      currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

    // const formattedDownPrice = downPrice
    //   ? formatMoney(intl, new Money(downPrice?.amount, downPrice?.currency))
    //   : null;
    const formattedInvSize = investmentSize
      ? formatMoney(
          intl,
          new Money(investmentSize.amount ? investmentSize.amount : investmentSize, config.currency)
        )
      : null;
    const s3docs = isOwnListing
      ? urlValues?.map((url) => {
          const fileName = url?.split('/').pop().replace('+', ' ');
          return { fileName, url };
        })
      : [];
    // console.log(258, urlValues, s3docs);
    const backButtonComponent = (
      <button
        className={css.backbtn}
        type="button"
        onClick={() => {
          history.goBack();
          // history.replace(
          //   createResourceLocatorString('ManageListingsPage', routeConfiguration(), {}, {})
          // );
        }}
      >
        <span>
          <BiArrowBack />{' '}
        </span>
        Back
      </button>
    );
    const infButtons = (
      <div className={css.infodiv}>
        <button
          className={classNames(css.infobtn, {
            [css.prpcls]: this.state.currentTab === 'propertyInfo',
          })}
          onClick={() => setCurrentTab('propertyInfo')}
        >
          Basic Information
        </button>
        <button
          className={classNames(css.infobtn, {
            [css.prpcls]: this.state.currentTab === 'otherInfo',
          })}
          onClick={() => setCurrentTab('otherInfo')}
        >
          Detailed Information
        </button>
      </div>
    );
    const priceSection = (
      <div className={css.prcsctn}>
        {/* <div className={css.price}>{investmentSizePerDeal.label}</div> */}
        {/* <div className={css.bkngamt}>Booking Amount - {formattedPrice}</div> */}
        <div className={css.bkngamt}>Investment amount required- {formattedPrice}</div>
        {/* <div> */}
        {currentAuthor?.id?.uuid === currentUser?.id?.uuid ? (
          <NamedLink
            className={css.finishListingDraftLink}
            name="EditListingPage"
            params={{
              id: currentListing?.id?.uuid,
              slug: createSlug(currentListing?.attributes?.title),
              type: LISTING_PAGE_PARAM_TYPE_EDIT,
              tab: 'details',
            }}
          >
            <EditIcon />

            <span>Edit Listing</span>
          </NamedLink>
        ) : null}
        {/* </div> */}
        {/* <NamedLink name="ProfileSettingsPage" className={css.editLink}> */}

        {/* <FormattedMessage id="ProfilePage.editProfileLinkDesktop" /> */}
        {/* </NamedLink> */}
      </div>
    );
    const locationSection = (
      <div>
        <div className={css.address}>
          <div className={css.addressWrap}>
            <div className={css.addrssIcon}>
              <MdLocationOn />
            </div>
            <div className={css.addrssInfo}>{address ? address : null}</div>
          </div>
          {verifiedDatas?.includes('location') ? (
            <span className={css.verifiedLabel}>
              Verified
              {/* <BsCheckCircleFill /> */}
            </span>
          ) : (
            <span className={css.notVerifiedLabel}>
              Not-Verified
              {/* <Cut /> */}
            </span>
          )}
        </div>

        <div className={css.mapWrapper}>
          <Map
            reusableContainerClassName={css.map}
            center={origin}
            mapRootClassName={css.mapRootClassName}
            className={css.mapbox}
          />
        </div>
      </div>
    );
    const docsSection = isOwnListing ? (
      s3docs?.length > 0 ? (
        <div className={css.docs}>
          <span>Documentation</span>
          <div className={css.alldoc}>
            {s3docs?.map((d, idx) => (
              <div key={idx} className={css.adocc}>
                <div className={css.adoc}>
                  <PDFimg />
                  <span>{d.fileName.replaceAll('%20', ' ')}</span>
                  <a href={d.url} target="_blank">
                    <AiOutlineEye />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null
    ) : null;

    const averageRating =
      currentListing?.author?.attributes?.profile?.metadata?.sumOfAllRatings !== undefined
        ? (
            currentListing?.author?.attributes?.profile?.metadata?.sumOfAllRatings /
            currentListing?.author?.attributes?.profile?.metadata?.ratingCount
          ).toFixed(1)
        : 0;

    const authorSection = (
      <div
        className={classNames(css.authorsec, {
          [css.authorseco]: this.state.currentTab === 'otherInfo',
        })}
      >
        <div className={css.authorinfo}>
          <div>
            <AvatarMedium
              user={currentListing?.author}
              disableProfileLink={packages?.name === 'basic' ? true : false}
            />
          </div>
          <div className={css.authordet}>
            <span
              onClick={() =>
                packages?.name === 'basic'
                  ? null
                  : history.push(
                      createResourceLocatorString(
                        'ProfilePage',
                        routeConfiguration(),
                        { id: currentAuthor.id.uuid },
                        {}
                      )
                    )
              }
            >
              {authorName}
            </span>
            <span>{'Entrepreneur'}</span>
          </div>
          <div className={css.ratingDiv}>
            <span>Rating {averageRating}</span>
            {typeof averageRating !== undefined ? (
              <div className={css.ratedv}>
                {[1, 2, 3, 4, 5].map((star) => (
                  <IconReviewStar key={`star-${star}`} isFilled={star <= averageRating} />
                ))}
              </div>
            ) : (
              <div className={css.ratedv}>
                {[1, 2, 3, 4, 5].map((star) => (
                  <IconReviewStar key={`star-${star}`} />
                ))}
              </div>
            )}
          </div>
        </div>
        {/* 
        <div className={css.rating}>
          <span>Rating 0.0</span>
          <div className={css.startrate}>
            <BsStar />
            <BsStar />
            <BsStar />
            <BsStar />
            <BsStar />
          </div>
        </div> */}
        <div></div>
      </div>
    );
    const saveButton =
      isOwnListing || currentUser?.attributes?.profile?.privateData?.isSuperAdmin ? null : (
        <Button
          className={css.bookButton}
          // ready={ready}
          // inProgress={updateInProgress}
          onClick={() => {
            if (hasBookedListing) {
              return history.push(
                createResourceLocatorString('OrderPage', routeConfiguration(), {
                  id: bookedTx?.id?.uuid,
                })
              );
            }
            if (alreadyBooked) {
              return history.push(
                createResourceLocatorString('OrderPage', routeConfiguration(), {
                  id: bookedTxId,
                })
              );
            }
            handleOrderSubmit({ isQuoteType });
          }}
        >
          {currentListing && hasBookedListing === null ? (
            <IconSpinner />
          ) : hasBookedListing || alreadyBooked ? (
            'Chat'
          ) : isQuoteType ? (
            'Pay'
          ) : zeroOrderRequest ? (
            <IconSpinner />
          ) : (
            'Contact Seller'
          )}
        </Button>
      );

    const verificableData = findOptionsForSelectFilter('verificableData', config.custom.filters);
    const infoDivs = (label, desc, key) => {
      // const nonVerifiableKeys =
      //   key !== 'lookingFor' &&
      //   key !== 'businessSector' &&
      //   key !== 'businessDesc' &&
      //   key !== 'investmentOffer';

      const verifiableKeys =
        key === 'businessType' || key === 'businessEstDate' || key === 'businessEmpCount';

      // console.log(222, key, verifiableKeys, verifiedDatas);

      if (!label || !desc) return null;
      return (
        <div className={css.infoone}>
          <span>{label}</span>
          <span>
            {desc}
            {verifiableKeys ? (
              verifiedDatas?.includes(key) ? (
                // verificableData?.map((f) => f.key)?.includes(key) ? (
                <span className={css.verifiedLabel}>
                  Verified
                  {/* <BsCheckCircleFill /> */}
                </span>
              ) : (
                <span className={css.notVerifiedLabel}>
                  Not-Verified
                  {/* <Cut /> */}
                </span>
              )
            ) : null}{' '}
          </span>
        </div>
      );
    };
    const otherInfo = (
      <div className={css.allinfo}>
        {lookingFor?.label ? infoDivs('Looking For', lookingFor.label, 'lookingFor') : null}
        {formattedInvSize
          ? infoDivs('Investment Size', formattedInvSize, 'formattedInvSize')
          : null}
        {investmentOffer ? infoDivs('Investment Offers', investmentOffer, 'investmentOffer') : null}
        {businessSector?.label
          ? infoDivs('Business Sector', businessSector.label, 'businessSector')
          : null}
        {businessType?.label
          ? infoDivs('Type of Business', businessType.label, 'businessType')
          : null}
        {businessDesc ? infoDivs('Business Description', businessDesc, 'businessDesc') : null}
        {dateOfEstablishment
          ? infoDivs('Date of Business Establishment', dateOfEstablishment, 'businessEstDate')
          : null}
        {employeeCount ? infoDivs('No of Employee', employeeCount, 'businessEmpCount') : null}
        {businessPitch ? infoDivs('The Pitch', businessPitch, 'businessPitch') : null}
        {businessMarket ? infoDivs('The Market', businessMarket, 'businessMarket') : null}
        {businessProgress ? infoDivs('Progress/Proof', businessProgress, 'businessProgress') : null}
        {businessObjective
          ? infoDivs('Objective/Future', businessObjective, 'businessObjective')
          : null}
      </div>
    );
    const { rating } = currentListing?.attributes?.metadata;
    // console.log(858, rating);
    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'Product',
          description: description,
          name: schemaTitle,
          image: schemaImages,
          ...brandMaybe,
          offers: {
            '@type': 'Offer',
            url: productURL,
            priceCurrency: price?.currency,
            price: schemaPriceNumber,
            availability: schemaAvailability,
          },
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.lwmn}>
            <div className={css.maindiv}>
              <div className={css.topDiv}>
                {backButtonComponent}
                {width > 767 ? infButtons : null}
              </div>
              {/* <div className={css.topDiv}>
                <div>
                  {backButtonComponent}
                  {(currentUser?.attributes?.profile?.privateData?.isSuperAdmin === true ||
                    isOwnListing) &&
                  currentStock === 0 ? (
                    <button
                      className={css.closeListing}
                      onClick={(event) => {
                        // console.log('first');
                        event.preventDefault();
                        event.stopPropagation();

                        onCloseListing(currentListing.id);

                        history.push(
                          createResourceLocatorString(
                            'ManageListingsPage',
                            routeConfiguration(),
                            {},
                            {}
                          )
                        );
                      }}
                    >
                      Close Listing
                    </button>
                  ) : null}
                </div>
                <div>{width > 767 ? infButtons : null}</div>
              </div> */}
              <div className={css.btmdiv}>
                <div className={css.imgsctn}>
                  <SectionGallery
                    listing={currentListing}
                    className={css.imggal}
                    fromPreview={true}
                    imgClassName={css.img}
                    wrapperClassName={css.wrapperClassName}
                    thumbnailPosition={width <= 767 ? 'left' : 'bottom'}
                  />
                </div>
                {width <= 767 ? <div className={css.smlscdiv}>{infButtons}</div> : null}
                {this.state.currentTab === 'propertyInfo' ? (
                  <div className={css.secdiv}>
                    {priceSection}
                    <div className={css.location}>
                      <div className={css.title}>
                        {title}
                        {typeof rating !== 'undefined' ? (
                          <div className={css.rtedv}>
                            {[1, 2, 3, 4, 5].map((star) => (
                              <IconReviewStar key={`star-${star}`} isFilled={star <= rating} />
                            ))}
                          </div>
                        ) : (
                          <div className={css.rtedv}>
                            {[1, 2, 3, 4, 5].map((star) => (
                              <IconReviewStar key={`star-${star}`} />
                            ))}
                          </div>
                        )}
                        {/* <span>
                          {isVerified ? (
                            <span className={css.verspn}>
                              <BiCheck /> Verified
                            </span>
                          ) : (
                            <span className={css.verspnRed}>
                              <Cut /> Un-Verified
                            </span>
                          )}
                        </span> */}
                      </div>
                      {locationSection}
                    </div>
                    <div className={css.desc}>{description}</div>
                    {docsSection}
                    {authorSection}
                    {saveButton}
                  </div>
                ) : (
                  <div className={css.secdiv}>
                    {priceSection}

                    {authorSection}
                    {otherInfo}
                    {saveButton}
                  </div>
                )}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.lineItemUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  customConfig: config.custom,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.lineItemUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  customConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    hasBookedListing,
    bookedTx,
  } = state.ListingPage;
  const { zeroOrderRequest, zeroOrderSuccess, zeroOrderError } = state.CheckoutPage;
  const { currentUser } = state.user;

  const getListing = (id) => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = (id) => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    bookedTx,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    zeroOrderRequest,
    zeroOrderSuccess,
    zeroOrderError,
    hasBookedListing,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (orderData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(orderData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onInitiateZeroOrder: (orderParams) => dispatch(initiateZeroOrder(orderParams)),
  onCloseListing: (listingId) => dispatch(closeListing(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
